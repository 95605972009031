html,
body {
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body {
  background-color: #f5f5f5;
}

  body:not(.fa) {
    font-family: 'Montserrat', sans-serif;
  }
  main {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    min-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .main-content {
    width: 100%;
  }
  .b-example-divider {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }
  
  .bi {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
  }
  
  .dropdown-toggle { outline: 0; }
  
  .nav-flush .nav-link {
    border-radius: 0;
  }
  
  .btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border: 0;
  }
  .btn-toggle:hover,
  .btn-toggle:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #d2f4ea;
  }
  
  .btn-toggle::after {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
    margin-left: .5em;
  }
  
  .btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
  }
  .btn-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
  }
  
  .btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;
  }
  .btn-toggle-nav a:hover,
  .btn-toggle-nav a:focus {
    background-color: #d2f4ea;
  }
  
  .scrollarea {
    overflow-y: auto;
  }
  
  .fw-semibold { font-weight: 600; }
  .lh-tight { line-height: 1.25; }
  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
.main-content {
    position: relative;
}
footer {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    // left: 0;
    width: 100%;
}
a:hover, .nav-link:hover, .developed-by:hover {
    color: #080808;
}
  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }
.admin-sidebar  {
  position: fixed;
  left: 0;
  right: 0;
  width: inherit;
}
  .admin-sidebar small.title {
    color: #080808;
    text-transform: uppercase;
  }
  .developed-by, .custom-link {
    color: #080808;
    text-decoration: none;
  }
  .active.admin-nav-item {
    color: #080808;
  }
  .admin-nav-item, .logout, .welcome-msg {
    color: #878787;
    text-decoration: none;
  }
  .pagination {
    margin-bottom: 0;
  }
  .page-item.active .page-link {
    background-color: #080808;
    border-color: #080808;
  }
  .page-link, .page-link:hover {
    color: #080808;
  }

  .btn-success {
    background-color: #20c997;
    border-color: #20c997;
  }
  .btn-outline-success {
    color: #20c997;
    border-color: #20c997;
  }
  .btn-outline-info {
    color: #080808;
    border-color: #080808;
  }
  .btn-outline-info:hover {
    color: white!important;
  }
  .custom-table-heading {
    background: #080808;
    background: -moz-linear-gradient(360deg, #080808 37%, #3e3e3e 76%, #040404 98%);
    background: -webkit-linear-gradient(360deg, #080808 37%, #3e3e3e 76%, #040404 98%);
    background: linear-gradient(360deg, #080808 37%, #3e3e3e 76%, #040404 98%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#080808",endColorstr="#0086c3",GradientType=1);
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 150px;
  }

  .select2-container {
    width: 100%!important;
  }

  a:hover {
    text-decoration: none;
  }
#customer_signature {
  width: 0;
  height: 0;
  padding: 0;
}
  .kbw-signature { width: 400px; height: 200px; }


  .form-signin {
    width: 100%;
    padding: 15px;
    margin: auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }



  // Navbar
  @media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 100px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: whitesmoke;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    z-index: 102;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.custom-overflow {
  overflow: hidden;
}